<template lang="pug">
  div
    img.w-100.p-absolute(src="@/assets/final/fond_relier.png" srcset="@/assets/final/fond_relier@2x.png 2x")
    .text-block.p-absolute.text-top.text-center(style="width: 70%")
      | {{ $t('points-à-relier.titre') }}
    svg.p-absolute.pad(:class="{ error }" ref="pad" viewBox="0 0 1920 1080" @touchmove.prevent="touchMove" @touchstart.prevent="touchStart" @touchend="touchEnd")
      circle.pin(
        v-for="n in 6" :key="'A'+n" :class="getPinClass(n)"
        :cx="getX(n)"
        :cy="getY(1)"
        r="40"
      )
      circle.pin(
        v-for="n in 6" :key="'B'+n" :class="getPinClass(6 + n)"
        :cx="getX(n)"
        :cy="getY(2)"
        r="40"
      )
      line.line(
        v-for="l in lines"
        :x1="getX(l.x1)"
        :x2="getX(l.x2)"
        :y1="getY(l.y1)"
        :y2="getY(l.y2)"
      )
      line.line.unfinished(
        v-if="unfinishedLine"
        :x1="unfinishedLineCoords.x1"
        :x2="unfinishedLineCoords.x2"
        :y1="unfinishedLineCoords.y1"
        :y2="unfinishedLineCoords.y2"
      )

</template>

<script>
export default {
  name: 'PointsARelier',

  watch: {
    value(newVal) {
      this.localValue = newVal
    }
  },

  data() {
    return {
      code: '',
      unlocked: false,
      lastPin: null,
      untouched: false,
      unfinishedLine: null,
      localValue: '',
      error: false
    }
  },

  computed: {
    lines() {
      const l = []
      for (let i = 1; i < this.localValue.length; i++) {
        const p1 = this.getPoint(this.localValue.charCodeAt(i - 1) - 65)
        const p2 = this.getPoint(this.localValue.charCodeAt(i) - 65)
        l.push({ x1: p1.x, y1: p1.y, x2: p2.x, y2: p2.y })
      }
      return l
    },

    unfinishedLineCoords() {
      const p1 = this.getPoint(this.lastPin - 1)
      return {
        x1: this.getX(p1.x),
        y1: this.getY(p1.y),
        x2: this.unfinishedLine.x / this.xratio,
        y2: this.unfinishedLine.y / this.yratio
      }
    }
  },

  mounted () {
    setTimeout(() => this.resized(), 100)
    this.resizeHandler = () => this.resized()
    window.addEventListener('resize', this.resizeHandler)
  },

  beforDestroy () {
    window.removeEventListener('resize', this.resizeHandler)
  },

  methods: {
    resized() {
      const rect = this.$refs.pad.getBoundingClientRect()
      this.xoffset = rect.x
      this.yoffset = rect.y
      this.xratio = rect.width / 1920
      this.yratio = rect.height / 1080
    },

    getPoint(pin) {
      const y = 1 + Math.floor(pin / 6)
      const x = 1 + (pin % 6)
      return { x, y }
    },

    touchStart(e) {
      if (this.untouched) {
        this.clear()
      }
      this.unfinishedLine = null
      const coords = e.changedTouches[0]
      const realTarget = document.elementFromPoint(coords.clientX, coords.clientY)
      if (realTarget.tagName.toLowerCase() === 'circle') {
        this.lastPin = parseInt(realTarget.classList[1].substr(4), 16)
        const ch = String.fromCharCode(64 + this.lastPin)
        if (!this.localValue.endsWith(ch)) {
          this.$sound('tick')
          this.localValue += ch
        }
      }
    },

    touchMove(e) {
      const coords = e.changedTouches[0]
      const realTarget = document.elementFromPoint(coords.clientX, coords.clientY)
      if (realTarget.tagName.toLowerCase() === 'circle') {
        this.lastPin = parseInt(realTarget.classList[1].substr(4), 16)
        const ch = String.fromCharCode(64 + this.lastPin)
        if (!this.localValue.endsWith(ch)) {
          this.$sound('tick')
          this.localValue += ch
        }
        this.unfinishedLine = null
      } else if (this.lastPin && realTarget && realTarget.tagName.toLowerCase() === 'svg') {
        this.unfinishedLine = { x: (coords.clientX - this.xoffset), y: (coords.clientY - this.yoffset) }
      } else {
        this.unfinishedLine = null
      }
    },

    touchEnd(e) {
      this.$emit('input', this.localValue)
      this.untouched = true
      this.unfinishedLine = null
      this.lastPin = null
    },

    getX(x) {
      return 360 + (x - 1) * 240
    },

    getY(y) {
      return 420 + (y - 1) * 240
    },

    getPinClass(n) {
      let cls = 'pin-' + n.toString(16)
      if (n === this.lastPin) {
        cls += ' current'
      }
      const ch = String.fromCharCode(64 + n)
      if (this.localValue.indexOf(ch) !== -1) {
        cls += ' used'
      }
      return cls
    },

    clear() {
      this.localValue = ''
      this.lastPin = null
      this.unfinishedLine = null
      this.$emit('input', '')
    },

    showError() {
      this.error = true
      setTimeout(() => {
        this.error = false
        this.clear()
      }, 750)
    }
  }
}
</script>

<style lang="less" scoped>
@color: rgb(229, 255, 0);
@color_error: rgb(255, 30, 0);

.pad {
  width: 100%;
  height: 100%;
  z-index: 1;

  .pin {
    fill: white;
    stroke: black;
    stroke-width: 8px;
    &.used {
      fill: @color;
    }
  }

  .line {
    stroke: @color;
    stroke-width: 30px;
    stroke-miterlimit: 10px;
    stroke-linecap: round;
    pointer-events: none;
    &.unfinished {
      stroke-width: 16px;
    }
  }

  &.error {
    .pin.used {
      fill: @color_error;
    }
    .line {
      stroke: @color_error;
    }
  }
}
</style>
