<template lang="pug">
  div
    img.w-100(src="@/assets/final/fond.png" srcset="@/assets/final/fond@2x.png 2x")
    .text-block.super-vilain
      | {{$t('idee.super-vilain')}}
    .text-block.titre(v-show="!seqProgress")
      span(v-if="remainingViews === 3") {{$t('idee.reconstituez-suite-attaques')}}
      span(v-else-if="lostLives === 1") {{$t('idee.reconstituez-suite-attaques-2')}}
      span(v-else) {{$t('idee.perdre-une-vie')}}
    .super-heros
      .text-block {{$t('idee.super-heros')}}
      .text-block.indicators(:class="{ red: lostLives < 1, bounce }")
        template(v-if="remainingViews > 0")
          img(src="@/assets/final/oeil.png" srcset="@/assets/final/oeil@2x.png 2x")
          div {{remainingViews}}
        template(v-if="lostLives < 1")
          img(src="@/assets/final/coeur.png" srcset="@/assets/final/coeur@2x.png 2x")
          div {{lostLives}}
    .sequence-items
      img.item_vs(@click="start()" v-show="seqProgress === 0"  src="@/assets/final/vs.png" srcset="@/assets/final/vs@2x.png 2x")
      img.item_01(v-show="seqProgress === 1"  src="@/assets/final/suite_01.png" srcset="@/assets/final/suite_01@2x.png 2x")
      img.item_02(v-show="seqProgress === 2"  src="@/assets/final/suite_02.png" srcset="@/assets/final/suite_02@2x.png 2x")
      img.item_03(v-show="seqProgress === 3"  src="@/assets/final/suite_03.png" srcset="@/assets/final/suite_03@2x.png 2x")
      img.item_04(v-show="seqProgress === 4"  src="@/assets/final/suite_04.png" srcset="@/assets/final/suite_04@2x.png 2x")
      img.item_05(v-show="seqProgress === 5"  src="@/assets/final/suite_05.png" srcset="@/assets/final/suite_05@2x.png 2x")
      img.item_06(v-show="seqProgress === 6"  src="@/assets/final/suite_06.png" srcset="@/assets/final/suite_06@2x.png 2x")
      img.item_07(v-show="seqProgress === 7"  src="@/assets/final/suite_07.png" srcset="@/assets/final/suite_07@2x.png 2x")
      img.item_08(v-show="seqProgress === 8"  src="@/assets/final/suite_08.png" srcset="@/assets/final/suite_08@2x.png 2x")
      img.item_09(v-show="seqProgress === 9"  src="@/assets/final/suite_09.png" srcset="@/assets/final/suite_09@2x.png 2x")
      img.item_10(v-show="seqProgress === 10" src="@/assets/final/suite_10.png" srcset="@/assets/final/suite_10@2x.png 2x")
</template>

<script>
const SPEED = 1500

export default {
  name: 'CombatFinal',

  data () {
    return {
      remainingViews: 3,
      lostLives: 1,
      seqProgress: 0,
      bounce: false
    }
  },

  beforDestroy () {
    if (this.interval) clearInterval(this.interval)
  },

  methods: {
      start () {
        if (!this.remainingViews) {
          this.lostLives--
          this.animateBounce()
          this.$sound('too_bad')
        } else {
          this.$sound('validate')
        }
        this.seqProgress = 1
        this.interval = setInterval(() => this.nextSequence(), SPEED)
      },

      animateBounce() {
        this.bounce = true
        setTimeout(() => { this.bounce = false }, 500)
      },

      nextSequence () {
        this.seqProgress++
        if (this.seqProgress === 11) this.stop()
      },

      stop () {
        if (this.remainingViews) {
          this.remainingViews--
          this.animateBounce()
        }
        if (!this.remainingViews && this.lostLives > 0) {
          this.lostLives = 0
        }
        if (this.interval) clearInterval(this.interval)
        this.seqProgress = 0
      }

  }
}
</script>

<style lang="less" scoped>
.text-block.super-vilain {
  position: absolute;
  top: 46px;
  right: 170px;
}

@keyframes bounce {
  from {
    transform: scale(1)
  }
  80% {
    transform: scale(1.3)
  }
  to {
    transform: scale(1)
  }
}

.super-heros {
  position: absolute;
  bottom: 46px;
  left: 46px;
  display: flex;
  flex-direction: row;

  .indicators {
    display: flex;
    flex-direction: row;
    margin-left: 46px;

    &.bounce {
      animation: bounce 250ms ease;
    }

    img {
      height: 40px;
      width: auto;
      margin-right: 12px;
    }

    &.red {
      color: #e4728a;
      border-color: #e4728a;
    }
  }
}

.text-block.titre {
  position: absolute;
  bottom: 46px;
  right: 46px;
}

.sequence-items {
    position: absolute;
    top: 48%;
    left: 51%;
    transform: translate(-50%, -50%);
    width: 20%;
    height: 45%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
        flex-shrink: 1;
        width: 100%;
        height: auto;
    }
}
</style>
