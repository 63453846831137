<template lang="pug">
  div
    menu-button
    points-a-relier(ref="pointsARelier" v-if="step === 0" v-model="points")
    div(v-else-if="step === 1")
      img.w-100.p-absolute(src="@/assets/final/fond_victoire.png" srcset="@/assets/final/fond_victoire@2x.png 2x")
      .p-absolute.absolute-center(style="width: 60%; margin: 0 auto")
        .text-block(style="margin-bottom: 2em")
          div Victoire !
          br
          div En combinant toutes les compétences gagnées, vous avez réussi à rétablir le système de géolocalisation que Super-Vilain avait piraté depuis son atelier !
          br
          div Vous réussissez donc à le localiser et vous vous retrouvez face à face avec Super-Vilain.
          div Mais celui-ci se jette sur vous ! Préparez votre suite d'attaques pour le combat !
        button(@click="step = 2") Affronter Super-Vilain !
    combat-final(v-else-if="step === 2")
</template>

<script>
// @ is an alias to /src
import MenuButton from '@/components/MenuButton.vue'
import CombatFinal from '@/components/CombatFinal.vue'
import PointsARelier from '@/components/PointsARelier.vue'

export default {
  name: 'VueIdee',

  components: {
    CombatFinal, PointsARelier, MenuButton
  },

  data() {
    return {
      step: 0,
      points: ''
    }
  },

  watch: {
    points(val) {
      if (val === 'ABHIDEKL' && this.step === 0) {
        this.step = 1
        this.$sound('ok')
      } else if (val && val.length) {
        this.$sound('error')
        this.$refs.pointsARelier.showError()
      }
    }
  }
}
</script>

<style lang="less" scoped>
#menu-button {
  right: 30px;
  top: 40px;
}
</style>
